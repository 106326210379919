<!-- https://heroicons.com/ -->
<script lang="typescript">
    const errorKey = 'emoji-sad';
    export let key = errorKey;
    export let strokeColor = 'text-gray-600';
    export let w = '6';
    export let h = '6';

    import { onMount } from 'svelte';

    // prettier-ignore
    const heroicons = {
        'trash': {paths: [{d:'M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16',},],},
        'emoji-sad': {paths: [{d:'M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z',},],},
        'user-add': {paths: [{d:'M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z',},],},
        'user-remove': {paths: [{d:'M13 7a4 4 0 11-8 0 4 4 0 018 0zM9 14a6 6 0 00-6 6v1h12v-1a6 6 0 00-6-6zM21 12h-6',},],},
        'question-mark-circle': {paths: [{d:'M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z',},],},
        'bell': {paths: [{d:'M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9',},],},
        'arrow-right': {paths: [{d:'M14 5l7 7m0 0l-7 7m7-7H3',},],},
        'share': {paths: [{d:'M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z',},],},
        'social': {paths: [{d:'M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z',},],},
        'active': {paths: [{d:'M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z',},],},
        'performance': {paths: [{d:'M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z',},],}
    };

    let icon = heroicons[key];

    const keyExists = (key) => {
        return Object.keys(heroicons).indexOf(key) >= 0;
    };

    onMount(() => {
        if (!keyExists(key)) {
            console.error(
                `Icon with key: '${key}' do not exist in Heroicons dict and we use the default key '${errorKey}' instead: `,
                heroicons
            );
            icon = heroicons[errorKey];
        }
    });
</script>

{#if icon}
    <svg
        class="h-{h} w-{w} stroke-current {strokeColor}"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor">
        {#each icon.paths as path, i}
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="{path.d}"></path>
        {/each}
    </svg>
{/if}
