<script>
    import CreateForm from './CreateForm.svelte';
    import { notifications, currentMembro, location } from './../stores';
    import { api } from './../Utils/server';
    import { create_rendevuo, join_rendevuo } from './queries';

    import rendevuos from './rendevuos-store';
    
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    const submit = (rendevuo) => {
        _createRendevuo(rendevuo);
        dispatch('created', rendevuo);
    };

    async function _createRendevuo(rendevuo) {
        if (!$currentMembro) return;

        const created = await api(
            create_rendevuo,
            {
                title: rendevuo.title,
                category: rendevuo.category,
                level: rendevuo.level,
                coordinates: rendevuo.coordinates,
                time: rendevuo.time.toISOString().split('.')[0] + '+00:00',
                about: rendevuo.about,
            },
            (response) => {
                return response.data.createRendevuo;
            }
        );
        created.holder = $currentMembro;
        rendevuos.add(created);

        // Not the best way to do this
        // but will work until we
        // inlude this into the rendevuo store
        const joined = await api(
            join_rendevuo,
            { _id: created._id },
            (response) => {
                if (response.data.joinRendevuo)
                    return response.data.joinRendevuo;
            }
        );

        if (joined) {
            rendevuos.join(created._id, {
                _id: $currentMembro._id,
                name: $currentMembro.name,
                picture: $currentMembro.picture,
            });
        }
        notifications.set({
            title: 'Successfully created!',
            text: 'You have now created the rendevuo.',
        });
    }

    const initial = {
        title: '',
        category: 'rendevuo',
        coordinates: [$location[0], $location[1]],
    };
</script>

<CreateForm
    submit="{submit}"
    on:cancel="{() => dispatch('cancel')}"
    initial="{initial}" />
