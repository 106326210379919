<script>
    import isWebview from 'is-ua-webview';
    let showModal = isWebview(window.navigator.userAgent);

    import Modal from './Modal.svelte';
</script>

{#if showModal}
    <Modal
        title="Welcome on board the Ekscito team!"
        on:cancel="{() => (showModal = false)}">
        <div slot="svg" class="justify-center items-center mx-auto w-12">
            <img src="./logo.png" alt="Workflow" />
        </div>
        <div class="space-y-2">
            <p class="">
                Just a friendly reminder that the experience is better if you
                use Chrome, Firefox or Safari instead.
            </p>
            <p>Anyway, let's go 👍</p>
        </div>
    </Modal>
{/if}
