<script>
    export let tooltipText = "Add some text here";
    export let w = '6';
    export let h = '6';

    import { fade } from 'svelte/transition';
    import { onMount } from 'svelte';

    import Heroicons from './Heroicons.svelte';

    let tooltipVisible = false;
    let menu = null;

    onMount(() => {
        const handleOutsideClick = (event) => {
            if (tooltipVisible && !menu.contains(event.target)) {
                tooltipVisible = false;
            }
        };

        const handleEscape = (event) => {
            if (tooltipVisible && event.key === 'Escape') {
                tooltipVisible = false;
            }
        };

        // add events when element is added to the DOM
        document.addEventListener('click', handleOutsideClick, false);
        document.addEventListener('keyup', handleEscape, false);

        // remove events when element is removed from the DOM
        return () => {
            document.removeEventListener('click', handleOutsideClick, false);
            document.removeEventListener('keyup', handleEscape, false);
        };
    });

</script>

<div bind:this="{menu}" class="relative flex items-center">
    <div class="{tooltipVisible ? 'z-10': ''}" on:click|stopPropagation="{() => {
        tooltipVisible = true;
        setTimeout(() => {
            tooltipVisible = false;
        }, 5000);
    }}"
    on:mouseover="{() => (tooltipVisible = true)}"
    on:mouseleave="{() => (tooltipVisible = false)}">
        <Heroicons key="question-mark-circle" w={w} h={h} />
    </div>
    {#if tooltipVisible}
        <div transition:fade="{{ duration: 100 }}" class="absolute -inset-y-8">
            <div
                class="px-2 whitespace-nowrap py-0.5 text-sm bg-orange-500 rounded-lg text-white"
            >
                {tooltipText}
            </div>
            <svg
                class=" text-orange-500 h-2 left-0 ml-2 top-full"
                x="0px"
                y="0px"
                viewBox="0 0 255 255"
                xml:space="preserve"
                ><polygon class="fill-current" points="0,0 127.5,127.5 255,0"
                    ></polygon></svg
            >
        </div>
    {/if}
</div>