<script>
    export let svg;
    import { categorySvgs as svgIcons } from './category-types';
</script>

<svg
    class="h-6 w-6 {svgIcons[svg].fill === 'currentColor'
        ? 'fill-current'
        : ''} {svgIcons[svg].stroke === 'currentColor'
        ? 'stroke-current'
        : ''}  text-gray-500"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="{svgIcons[svg].viewBox}"
    stroke="{svgIcons[svg].stroke}"
    fill="{svgIcons[svg].fill}">
    {#each svgIcons[svg].paths as path, i}
        <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="{path.stroke_width}"
            d="{path.d}"
        ></path>
    {/each}
</svg>
