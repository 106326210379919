import { gql } from 'apollo-boost';

const _membro_fields = gql`
    fragment membroFields on Profile {
        _id
        name
        picture
    }
`;

const _rendevuo_fields = gql`
    fragment queryFields on Rendevuo {
        _id
        title
        time
        coordinates
        distance
        category
        level
        map
        holder {
            ...membroFields
        }
        capacity
        about
        membros {
            ...membroFields
        }
        notoj {
            text
            created
        }
        deleted
    }
    ${_membro_fields}
`;

const find_matches = gql`
    query findRendevuos($coordinates: [Float!], $categories: [String]!, $levels: [String]) {
        findRendevuos(
            match: {
                coordinates: $coordinates
                categories: $categories
                levels: $levels
            }
        ) {
            ...queryFields
        }
    }
    ${_rendevuo_fields}
`;

const find_latest = gql`
    query findLatest {
        findRendevuos(match: { categories: [] }) {
            ...queryFields
        }
    }
    ${_rendevuo_fields}
`;

const get_single = gql`
    query getSingle($_id: String!, $coordinates: [Float!]) {
        getRendevuo(_id: $_id, coordinates: $coordinates) {
            ...queryFields
        }
    }
    ${_rendevuo_fields}
`;

const get_held_and_joined = gql`
    query getHeldAndJoined {
        getHeldAndJoined {
            ...queryFields
        }
    }
    ${_rendevuo_fields}
`;

const create_rendevuo = gql`
    mutation createRendevuo(
        $title: String!
        $time: Datetime!
        $coordinates: [Float!]!
        $category: String!
        $level: String!
        $about: String
    ) {
        createRendevuo(
            rendevuo: {
                title: $title
                time: $time
                coordinates: $coordinates
                category: $category
                level: $level
                about: $about
            }
        ) {
            ...queryFields
        }
    }
    ${_rendevuo_fields}
`;

const join_rendevuo = gql`
    mutation joinRendevuo($_id: String!) {
        joinRendevuo(_id: $_id)
    }
`;

const leave_rendevuo = gql`
    mutation leaveRendevuo($_id: String!) {
        leaveRendevuo(_id: $_id)
    }
`;

const cancel_rendevuo = gql`
    mutation cancelRendevuo($_id: String!, $notification: String) {
        cancelRendevuo(_id: $_id, notification: $notification)
    }
`;


export {
    find_matches,
    get_single,
    find_latest,
    get_held_and_joined,
    create_rendevuo,
    join_rendevuo,
    leave_rendevuo,
    cancel_rendevuo,
};
