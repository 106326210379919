<script>
    export let items = ['cold-water-dip', 'rendevuo'];
    export let selected = 'rendevuo';
    
    import CategoryIcon from './CategoryIcon.svelte';

    import { fade } from 'svelte/transition';
    import excapeOrClickOutside from './../Actions/escapeOrClickOutside';
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    const isSelected = (item) => {
        return selected === item;
    };
    
    const change = (item) => {
        selected = item;
        menuVisible = false;
        dispatch('change', item)
    }

    function _focus(e) {
        e.focus();
    }

    let menuVisible = false;
</script>

<div class="mt-1 relative" use:excapeOrClickOutside={menuVisible} on:excapeOrClickOutside={() => menuVisible = false}>
    <button
        use:_focus
        on:click="{() => (menuVisible = !menuVisible)}"
        type="button"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        class="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-orange-500 focus:border-orange-500 sm:text-sm">
        <span class="flex items-center">
            <CategoryIcon svg="{selected}" />
            <span class="ml-3 text-gray-600 block truncate">{selected.replace(/-/g, ' ')}</span>
        </span>

        <span
            class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <!-- Heroicon name: selector -->
            <svg
                class="h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true">
                <path
                    fill-rule="evenodd"
                    d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clip-rule="evenodd"></path>
            </svg>
        </span>
    </button>
    {#if menuVisible}
        <div
            transition:fade="{{ duration: 100 }}"
            class="z-10 absolute mt-1 w-full rounded-md bg-white shadow-lg">
            <ul
                tabindex="-1"
                role="listbox"
                aria-labelledby="listbox-label"
                aria-activedescendant="listbox-item-3"
                class="max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {#each items as item, i}
                    <li
                        on:click="{change(item)}"
                        id="listbox-item-{i}"
                        role="option"
                        class="text-gray-900 hover:bg-orange-600 hover:text-white cursor-pointer select-none relative py-2 pl-3 pr-9">
                        <div class="flex items-center">
                            <CategoryIcon svg="{item}" />
                            <span
                                class="ml-3 block truncate {isSelected(item) ? 'font-semibold' : 'font-normal'}">
                                {item.replaceAll('-', ' ')}
                            </span>
                        </div>

                        {#if isSelected(item)}
                            <span
                                class="absolute inset-y-0 right-0 flex items-center pr-4">
                                <!-- Heroicon name: check -->
                                <svg
                                    class="h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path
                                        fill-rule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clip-rule="evenodd"></path>
                                </svg>
                            </span>
                        {/if}
                    </li>
                {/each}
            </ul>
        </div>
    {/if}
</div>