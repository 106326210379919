<script>
    import Modal from './../UI/Modal.svelte';
    import Menu from './../UI/HeaderMenu.svelte';

    import { findMembro, createMembro, setConsent, readNotu } from './membro.js';
    import { currentMembro } from './../stores';

    import { Magic } from 'magic-sdk';


    async function digestMessage(message) {
        const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
        const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8);           // hash the message
        const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string

        return hashHex;
    }


    const magic = new Magic(MAGIC_SECRET_KEY);

    $: email = ''
    $: isAuthenticated = false

    magic.user.isLoggedIn().then(async (is) => {
        if (is) {
            email = (await magic.user.getMetadata()).email;
            login_succes(await magic.user.getIdToken());
        }
    });

    async function login() {
        const token = await magic.auth.loginWithMagicLink({ email });
        
        if (token) {
            login_succes(token);
        } else {
            logout();
        }
    }

    const login_succes = async (token) => {
        window.sessionStorage.setItem('token', token);

        const membro = await findMembro();
        // const emailHashCode = s => s.split('').reduce((a,b) => (((a << 5) - a) + b.charCodeAt(0))|0, 0);
        const emailSplit = email.split('@');
        const dhash = await digestMessage(emailSplit[1]);
        const name = emailSplit[0] + '(' + dhash + ')';
        const color = dhash.substring(0, 6);
        const picture = 'https://eu.ui-avatars.com/api/?name=' + name + '&background=' + color + '&rounded=true';
        $currentMembro = { ...membro, authToken: token, email: email, name: name, picture: picture };

        console.info('Current membro: ', $currentMembro);
        isAuthenticated = true;
    }

    const logout = async () => {
        await magic.user.logout();
        isAuthenticated = false;
        window.sessionStorage.removeItem('token');
    }

    $: showPrivacyUsePolicyModal =
        isAuthenticated &&
        $currentMembro &&
        (!$currentMembro._id || !$currentMembro.consent);

    let accepted = false;
    const cancelModal = () => {
        logout();
        accepted = false;
        showPrivacyUsePolicyModal = false;
    };
    const acceptTerms = () => {
        if (!accepted) return;

        if (!$currentMembro._id) {
            console.log('Creating membro with consent');
            (async () => {
                const membro = await createMembro(accepted);
                if (membro)
                    $currentMembro = Object.assign($currentMembro, {
                        ...membro,
                    });

                return membro;
            })();

            $currentMembro = Object.assign($currentMembro, {
                _id: -1,
                consent: true,
            });
        } else {
            console.log('Updating membro with consent');
            (async () => {
                const consent = await setConsent($currentMembro._id);
                $currentMembro = Object.assign($currentMembro, {
                    consent: consent,
                });

                return consent;
            })();

            $currentMembro = Object.assign($currentMembro, {
                consent: accepted,
            });
        }

        showPrivacyUsePolicyModal = false;
    };

    // onDestroy(unsubscribe);
</script>

<div>
    {#if showPrivacyUsePolicyModal}
        <Modal
            title="Accept our Privacy and Use Policy"
            on:cancel="{cancelModal}">
            <div class="flex items-start mb-2">
                <div class="flex items-center h-10">
                    <p>
                        It is very brief and understandable so please carefully
                        read it <a
                            class="underline text-red-800 font-black"
                            href="https://ekscito.com/privacy-en.html"
                            rel="noreferrer"
                            target="_blank">here</a> before accepting.
                    </p>
                </div>
            </div>
            <div class="flex items-start">
                <div class="flex items-center h-5">
                    <input
                        id="accept"
                        name="accept"
                        type="checkbox"
                        bind:checked="{accepted}"
                        class="focus:ring-orange-500 h-4 w-4 text-orange-600 border-gray-300 rounded" />
                </div>
                <div class="ml-3 text-sm">
                    <p class="text-gray-500">
                        I accept the Privacy and Use Policy.
                    </p>
                </div>
            </div>

            <div slot="footer">
                <button
                    type="button"
                    class="mt-3 sm:mt-0 btn btn-gray"
                    on:click="{cancelModal}">Close</button>
                <button
                    type="button"
                    class="mt-3 sm:mt-0 hop btn {!accepted
                        ? 'cursor-not-allowed text-orange-300 bg-orange-100 focus:outline-white'
                        : 'btn-orange'}"
                    on:click="{acceptTerms}">Accept</button>
            </div>
        </Modal>
    {/if}

    <div class="ml-4 flex items-center md:ml-6">
        {#if isAuthenticated && $currentMembro}
            <div class="ml-3 relative">
                <Menu
                    currentMembro="{currentMembro}"
                    on:logout="{() => {
                        logout();
                    }}"
                    on:read="{(a) => {
                        readNotu(a.detail._id).then(result => {
                            if (result) $currentMembro.notoj = $currentMembro.notoj.filter(n => n._id != a.detail._id);
                        });
                    }}" />
            </div>
        {:else}
            <div class="animate-bounce-right">
                <!-- <Heroicons key="arrow-right" h="7" w="7" strokeColor="text-orange-600" /> -->
                <svg class="h-7 w-7 text-orange-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
            </div>
            <div class="relative">
                <button type="button" on:click="{() => login()}" disabled={!/^\S+@\S+\.\S{2,}$/.test(email)} class="absolute ml-0 inset-y-0 left-0 flex items-center px-4 rounded-l-lg {!/^\S+@\S+\.\S{2,}$/.test(email) ? 'cursor-not-allowed text-white opacity-50 bg-gray-200' : 'btn-orange'}">Sign in</button>
                <input id="email" class="w-full h-10 pl-24 placeholder-gray-300 rounded-lg focus:outline-none focus:shadow-none" type="text" bind:value="{email}" placeholder="you@somewhere.com"/>
            </div>
        {/if}
    </div>
</div>

<style>
    @-webkit-keyframes bounce-right {
        0%, 100% {
            transform: translateX(-25%);
            -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
                    animation-timing-function: cubic-bezier(0.8,0,1,1);
        }

        50% {
            transform: none;
            -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
                    animation-timing-function: cubic-bezier(0,0,0.2,1);
        }
    }

    @keyframes bounce-right {
        0%, 100% {
            transform: translateX(-25%);
            -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
                    animation-timing-function: cubic-bezier(0.8,0,1,1);
        }

        50% {
            transform: none;
            -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
                    animation-timing-function: cubic-bezier(0,0,0.2,1);
        }
    }

    .animate-bounce-right {
        -webkit-animation: bounce-right 1s infinite;
                animation: bounce-right 1s infinite;
    }
</style>
