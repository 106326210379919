import ApolloClient from 'apollo-boost';


export function createClient(uri) {
    const client = new ApolloClient({
        uri: uri,
        request: async operation => {
            operation.setContext({
                headers: {
                    'x-membro-token': window.sessionStorage.getItem("token") || '',
                },
            });
        },
        onError: ({ networkError, graphQLErrors }) => {
            console.log('graphQLErrors', graphQLErrors);
            console.log('networkError', networkError);
        },
    });

    return client;
}
