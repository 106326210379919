<script>
    import Header from './UI/Header.svelte';
    import Location from './Rendevuo/Location.svelte';
    import List from './Rendevuo/RendevuoList.svelte';
    import Create from './Rendevuo/Create.svelte';
    import Webview from './UI/Webview.svelte';

    import { blur, fade } from 'svelte/transition';

    import { currentMembro, hash, location } from './stores';

    import { setClient } from 'svelte-apollo';
    import { createClient } from './Utils/apollo.js';

    import StackdriverErrorReporter from 'stackdriver-errors-js';

    // Setup error logging to send to Stackdriver in Google Cloud
    if (PRODUCTION_ENV) {
        window.addEventListener('DOMContentLoaded', function () {
            var errorHandler = new StackdriverErrorReporter();
            errorHandler.start({
                key: STACKDRIVER_KEY,
                projectId: STACKDRIVER_PROJECT_ID,
                disabled: STACKDRIVER == undefined,
            });
        });
    }

    if ('serviceWorker' in navigator) {
        console.log('Registering service worker...');
        navigator.serviceWorker.register('./service-worker.js', {scope: '.'});
    }

    setClient(createClient(ENV_BACKEND_URI || 'http://localhost:8000/graphql'));

    let showCreate = false;
    $: showCreate = $hash == '#c';

    function closeCreate() {
        window.location.hash = '';
    }

    let createVisible = showCreate;
    let listVisible = !showCreate; 
</script>

<Webview />

<Header />

<main class="antialiased text-gray-800">
    <section class="mx-auto max-w-4xl lg:max-w-7xl px-4">
        {#if showCreate && $location && !listVisible}
           <div
               class="pt-4 lg:pt-8 pb-3"
               transition:blur={{duration:300}} on:introstart="{() => createVisible = true}" on:outroend="{() => createVisible = false}">
                <Create
                    on:created="{closeCreate}"
                    on:cancel="{closeCreate}"
                     />
                <!-- <CreateRendevuo
                    map="{Map}"
                    create="{_createRendevuo}"
                    on:cancelCreate="{closeCreate}"
                    on:created="{closeCreate}" /> -->
            </div>
        {/if}
        {#if !showCreate && !createVisible}
            <div class="pt-4 space-y-4 lg:pt-8 lg:space-y-8 " transition:blur={{duration:300}} on:introstart="{() => listVisible = true}" on:outroend="{() => listVisible = false}">
                <Location /> 
                <List />
            </div>
        {/if}
    </section>
</main>

{#if $currentMembro && !showCreate}
    <div
        class="z-20 animate-pulse fixed bottom-0 m-6 right-0 md:right-12 lg:right-20 lg:mb-20 xl:right-64 ">
        <button
            on:click="{() => {
                window.location.hash = '#c';
                showCreate = true;
            }}"
            aria-label="Create rendevuo"
            class="p-0 w-12 h-12 lg:w-16 lg:h-16 bg-orange-600 rounded-full hover:bg-orange-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">
            <svg
                viewBox="0 0 20 20"
                enable-background="new 0 0 20 20"
                class="w-8 h-8 lg:w-10 lg:h-10 inline-block">
                <path
                    fill="#FFFFFF"
                    d="M16,10c0,0.553-0.048,1-0.601,1H11v4.399C11,15.951,10.553,16,10,16c-0.553,0-1-0.049-1-0.601V11H4.601 C4.049,11,4,10.553,4,10c0-0.553,0.049-1,0.601-1H9V4.601C9,4.048,9.447,4,10,4c0.553,0,1,0.048,1,0.601V9h4.399 C15.952,9,16,9.447,16,10z"
                ></path>
            </svg>
        </button>
    </div>
{/if}

<style>
    @import url(https://fonts.googleapis.com/css?family=Oswald:300&display=swap);
</style>
