import { readable, derived, writable, get } from 'svelte/store';

const currentMembro = writable();
const notifications = writable();

const locationKey = 'location';
const _read = () => {
    const value = window.sessionStorage.getItem(locationKey);
    if (!value) return null;

    const split = value.split(',');
    return [parseFloat(split[0].trim()), parseFloat(split[1].trim())];
};
const _location = writable();
_location.update(_read);
const location = {
    subscribe: _location.subscribe,

    set: (data) => {
        if (data) {
            _location.set(data);
            window.sessionStorage.setItem(locationKey, data);
        }
    },
    ask: () => {
        if(!navigator.geolocation) {
            return;
        }
    
        const options = {
            enableHighAccuracy: true,
            maximumAge: 1*60*1000,
            timeout: 27000
        };
        navigator.geolocation.getCurrentPosition(
            position => { location.set([position.coords.latitude, position.coords.longitude]) },
            error => { console.log(error); location.set([]); },
            options
        );
    },
    read: _read,
}

const hash = readable(window.document.location.hash, function start(set) {
    const update = () => {
        set(window.document.location.hash);
    };
    window.addEventListener('hashchange', update, false);
    return function stop() {
        window.removeEventListener('hashchange', update, false);
    };
});


const hashed = derived(hash, ($hash) =>
    (() => {
        var split = $hash.split('/');
        switch (split[0]) {
            case '#r':
                return split[1];
            default:
                return undefined;
        }
    })()
);

const _filter = writable({}); // should be proper object with empty-function (to determine whether state is LATEST or FILTERED)
const filterState = {
    subscribe: _filter.subscribe,

    set: data => {
        _filter.set(data);
    },
    setCategories: value => {
        _filter.update(filter => {
            return Object.assign(filter, {'categories': value});
        });
    },
    setLevels: value => {
        _filter.update(filter => {
            return Object.assign(filter, {'levels': value});
        });
    },
    setLocation: value => {
        _filter.update(filter => {
            return Object.assign(filter, {'location': value});
        });
    },
    setLinked: value => {
        _filter.update(filter => {
            return Object.assign(filter, {'linked': value});
        });
    },
    setSingle: value => {
        _filter.update(filter => {
            return Object.assign(filter, {'single': value});
        });
    },
}
location.subscribe(filterState.setLocation);

const states = Object.freeze({'LATEST': 1, 'FILTERED': 2, 'SINGLE': 3, 'LINKED': 4});
const searchState = readable(states.LATEST, set => { // could probably be merged with filterState, maybe?
    hashed.subscribe(value => {
        filterState.setSingle(value);
        if (value) {
            set(states.SINGLE);
        } else {
            _filter.update(filter => {return filter}); // refresh and notify subscribers that filters are enabled
        }
    });
    filterState.subscribe(value => {
        if (value.single) return; // if a link has been sent filtering is disabled since it doesn't make sense

        if (value.linked) {
            set(states.LINKED);
        } else if (value.location && get(currentMembro)) {
            set(undefined);
            set(states.FILTERED);
        } else {
            set(states.LATEST);
        }
    });
});
searchState.states = states;

// Subscriptions

hashed.subscribe((value) => {
    if (!value) {
        window.document.location.hash = '';
        return;
    }
});

currentMembro.subscribe(membro => {
    if (!membro) return;

    if (!location.read() && membro.location) {
        notifications.set({title: 'Location approximated', text: 'You appear to be in the ' + membro.location.city + ' area. You can make it more accurate if you click the compass arrow to the right in the box below to see rendevuos near you.'});
        location.set([membro.location.latitude, membro.location.longitude]);
    }

    setTimeout(() => {
        _filter.update(filter => {return filter});
    }, 200);
});

export { currentMembro, location, hashed, hash, filterState, searchState, notifications };
