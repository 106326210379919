<script>
    import { currentMembro, filterState, searchState, notifications } from './../stores';
    import { api } from '../Utils/server';
    import Rendevuo from './RendevuoItem.svelte';
    import Notification from './../UI/Notification.svelte';
    
    import rendevuos from './rendevuos-store';
    import { find_matches, get_single, find_latest, get_held_and_joined } from './queries';
    
    import { scale, slide } from 'svelte/transition';
    import LoadingCard from '../UI/LoadingCard.svelte';

    let loading = true;
    let showResult = false;

    searchState.subscribe(state => {
        filterState.subscribe(async filter => {
            loading = true;        
            const q = (() => {
                switch (state) {
                    case searchState.states.SINGLE: return [get_single, { _id: filter.single, coordinates: filter.location }];
                    case searchState.states.FILTERED: return [find_matches, { coordinates: filter.location, categories: filter.categories, levels: filter.levels }];
                    case searchState.states.LINKED: return [get_held_and_joined, {}];
                    case searchState.states.LATEST:
                    default: return [find_latest, {}];
                }
            })();

            const result = await api(q[0], q[1], (data) => {
                let r = data.data.findRendevuos ? data.data.findRendevuos : [];
                r = data.data.getRendevuo ? [data.data.getRendevuo] : r      
                r = data.data.getHeldAndJoined ? data.data.getHeldAndJoined : r

                return r;
            });
            
            rendevuos.set(result);
            setTimeout(() => {
                loading = false;
            }, 1000)
        })();
    });

    function join(event) {
        rendevuos.join(event.detail._id, event.detail.membro);
        notifications.set({title: 'Successfully joined!', text: 'Hi membro, you have now joined the rendevuo.'});
    }

    function leave(event) {
        rendevuos.leave(event.detail._id, event.detail._mid);
        notifications.set({title: 'Successfully left!', text: 'Hi membro, you have now left the rendevuo.'}); 
    }

    function cancel(event) {
        rendevuos.cancel(event.detail._id, event.detail.notoj);
        notifications.set({title: 'Successfully canceled!', text: 'Hi membro, you have now canceled the rendevuo.'});
    }

</script>
<section>
    <Notification  />
    <ul class="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-10 xl:grid-cols-3 xl:gap-12">
        {#if loading}
                <li class="col-span-1 flex flex-col" transition:scale|local on:introstart="{() => showResult = false }" on:outroend="{() => showResult = true}">
                    <LoadingCard />
                </li>
                {#each Array(2) as _}
                    <li transition:scale|local class="col-span-1 flex flex-col">
                        <LoadingCard />
                    </li>
                {/each}
        {/if}
        {#if showResult && $rendevuos}
            {#each $rendevuos as rendevuo (rendevuo._id)}
                <li transition:scale|local class="col-span-1 flex flex-col">
                    <Rendevuo
                        currentMembro={currentMembro}
                        rendevuo={rendevuo}
                        on:cancel={cancel}
                        on:join={join}
                        on:leave={leave} />
                </li>    
            {/each}
        {/if}    
    </ul>    
</section>
