const excapeOrClickOutside = (element, condition) => {
    let _condition = condition;

    const handleOutsideClick = (event) => {
        if (_condition && !element.contains(event.target)) {
            element.dispatchEvent(new CustomEvent('excapeOrClickOutside'));
        }
    };

    const handleEscape = (event) => {
        if (_condition && event.key === 'Escape') {
            element.dispatchEvent(new CustomEvent('excapeOrClickOutside'));
        }
    };

    // add events when element is added to the DOM
    document.addEventListener('click', handleOutsideClick, false);
    document.addEventListener('keyup', handleEscape, false);

    return {
        update (newCondition) {
            _condition = newCondition;
        },    
        destroy () {
            // remove events when element is removed from the DOM
            document.removeEventListener('click', handleOutsideClick, false);
            document.removeEventListener('keyup', handleEscape, false);
        }
    }
}

export default excapeOrClickOutside;
