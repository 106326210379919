<script>
    import { navigateTo } from '../Utils/location.js';
    import CategoryIcon from '../UI/CategoryIcon.svelte';
    
    export let src = './location-1.png';
    export let coordinates = [];
    export let category = '';
    export let distance = 0;
    distance = distance < 0 ? '?' : distance;

    let navigateUrl = navigateTo(coordinates);
</script>

    <div>
        <!-- Image map behind the detail card -->
        <div
            class="relative overflow-hidden sm:pb-1/3 pb-1/2 md:pb-3/5 lg:pb-1/2 xl:pb-3/5 shadow-md flex flex-1 flex-col">
            <div
                class="absolute z-10 rounded-full mx-4 mt-4 bg-white p-1 ring ring-orange-600 ring-offset-4 ring-offset-orange-100">
                <div class="flex px-1 space-x-1">
                    <CategoryIcon svg="{category}" />
                    <span class="pt-1 text-gray-500 text-sm">
                        {distance} km
                    </span>
                </div>
            </div>
            <a href="{navigateUrl}" rel="noreferrer" target="_blank">
                <img
                    class="absolute top-0 h-full w-full object-cover rounded-lg overflow-hidden"
                    src="{src}"
                    alt="map" />
            </a>
        </div>
    
        <!-- Confimation of last member leaving -->
        <slot name="confirmation" />

        <!-- Rendevuo detail card -->
        <div
            class="relative px-4 -mt-20">
            <slot name="card" />
        </div>
    </div>
