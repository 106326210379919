<script>
    import { fade } from 'svelte/transition';
    import excapeOrClickOutside from '../Actions/escapeOrClickOutside';

    // import Heroicons from './Heroicons.svelte';

    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    export let currentMembro;

    let menuVisible = false;
</script>

<div use:excapeOrClickOutside={menuVisible} on:excapeOrClickOutside={() => menuVisible = false}>
    <div
        on:click="{() => {
            menuVisible = !menuVisible;
        }}"
    >
        <div
            class="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-orange-600 focus:ring-white"
            id="user-menu"
            aria-haspopup="true"
        >
            <span class="sr-only">Open user menu</span>
            {#if $currentMembro.notoj && $currentMembro.notoj.length > 0}
            <span class="h-3 w-3 animate-ping absolute rounded-full bg-green-400 opacity-75 bottom-0 right-0"></span>
            <span class="h-3 w-3 absolute rounded-full bg-green-500 bottom-0 right-0"></span>                
            {/if}
                
            <img
                class="cursor-pointer h-10 w-10 rounded-full"
                src="{$currentMembro.picture}"
                alt="Picture of {$currentMembro.name}"
            />
        </div>
    </div>
    {#if menuVisible}
        <div
            transition:fade="{{ duration: 100 }}"
            class="z-10 origin-top-right absolute divide-y divide-gray-100 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu"
        >
            <div class="py-1">
                <a href="https://ekscito.com"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                    role="menuitem">About Ekscito</a
                >
            </div>
            {#each $currentMembro.notoj as notu}
            <div class="py-1 bg-green-100" transition:fade="{{ duration: 100 }}">
                <a href="/#r/{notu.rendevuo}"
                    on:click="{() => {
                        menuVisible = false;
                        setTimeout(() => {dispatch('read', notu);}, 600);
                    }}"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-green-300 cursor-pointer"
                    role="menuitem">{notu.text}</a>
                <!-- <span class="float-right top"><Heroicons key="trash" /></span> -->
            </div>                
            {/each}
            <div class="py-1">
                <span
                    on:click="{() => {
                        dispatch('logout');
                    }}"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                    role="menuitem">Sign out</span
                >
            </div>
        </div>
    {/if}
</div>
