<script>
    export let submit = () => {
        console.log('default submit with rendevuo values', rendevuo);
    };

    export let initial = {
        title: '',
        category: 'rendevuo',
        coordinates: [0, 0],
    };

    // What
    import SelectMenu from '../UI/SelectMenu.svelte';
    import { categoryKeys } from '../UI/category-types';

    // Level
    import Heroicons from '../UI/Heroicons.svelte';

    // Where
    import Map from './../Map/MapCopy.svelte';

    // When
    import { formatDate, partOfTheDay } from './../Utils/date';
    import * as chrono from 'chrono-node';

    import { createEventDispatcher, onMount } from 'svelte';
    const dispatch = createEventDispatcher();
    
    // Initialization
    let whenInitial = 'Tomorrow at 19:30';
    let whenExaples = 'Eg: friday at 11:00 - in 3 hours - next monday at 21.'; 
    let whenText = whenExaples;
    let invalid = true;
    
    let aboutMaxLength = 80;
    let aboutCharsLeft = aboutMaxLength;
    
    const init = () => {
        return {
            ...initial,
            time: chrono.parse(whenInitial)[0].start.date(),
            about: '',
            level: 'social',
        };
    };
    let rendevuo = init();
    
    // Very simple validation for now
    const validate = () => {
        invalid = rendevuo.time < Date.now(); 
    }
    validate();
    
    const inputHandler = ({ target }) => {
        const { name, value } = target;
        // When
        if (name === 'when') {
            rendevuo.time = chrono.parse(value)[0]
                ? chrono.parse(value)[0].start.date()
                : chrono.parse(whenInitial)[0].start.date();
            whenText = value.length > 0 ? formatDate(rendevuo.time) : whenExaples; 
        }
        
        // About - since we bind to rendevuo.about then we only need to count
        if (name === 'about') {
            aboutCharsLeft = aboutMaxLength - value.length;
        }
        validate()
    };

    const capitalizeFirstLetter = ([first, ...rest]) =>
        first ? first.toUpperCase() + rest.join('') : '';

    $: {
        rendevuo.title =
            capitalizeFirstLetter(partOfTheDay(rendevuo.time)) +
            ' ' +
            rendevuo.category.replace(/-/g, ' ');
    }

    const _submit = () => {
        invalid = true;
        dispatch('submit', rendevuo);
        submit(rendevuo);
        rendevuo = init();
        validate();
    };

    const _cancel = () => {
        dispatch('cancel');
        rendevuo = init();
        validate();
    };
</script>

<section>
    <form on:submit|preventDefault="{_submit}">
        <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div class="md:grid md:grid-cols-3 md:gap-6">
                <!-- Summary -->
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                        {rendevuo.title}
                    </h3>
                    <div class="mt-1 space-y-1 text-sm text-gray-500">
                        <p>
                            <span class="text-orange-600">Location</span>
                            {rendevuo.coordinates.map(
                                (v) => ' ' + v.toFixed(3)
                            )}
                        </p>
                        <p class="font-semibold">
                            {formatDate(rendevuo.time)}.
                        </p>
                        <p class="italic">{rendevuo.about}</p>
                    </div>
                </div>

                <div class="mt-5 md:mt-0 md:col-span-2">
                    <div class="grid grid-cols-6 gap-5">
                        <!-- What as in activity type -->
                        <div class="col-span-6 sm:col-span-3">
                            <label
                                for="what"
                                class="block text-sm font-medium text-gray-700"
                                >What</label>
                            <SelectMenu
                                items="{categoryKeys()}"
                                selected="{rendevuo.category}"
                                on:change="{({ detail: value }) =>
                                    (rendevuo.category = value)}" />
                        </div>

                        <!-- Where as in location and the Map -->
                        <div class="col-span-6">
                            <label
                                for="where"
                                class="block text-sm font-medium text-gray-700"
                                >Where</label>
                            <div class="w-full h-64">
                                <Map
                                    on:change="{({ detail: value }) => {
                                        rendevuo.coordinates = [
                                            value.lat,
                                            value.lon,
                                        ];
                                    }}"
                                    zoom="7"
                                    initial="{{
                                        lat: rendevuo.coordinates[0],
                                        lon: rendevuo.coordinates[1],
                                    }}" />
                            </div>
                        </div>

                        <!-- When as in time of the rendevuo -->
                        <div class="col-span-6 sm:col-span-6 lg:col-span-3">
                            <div class="flex justify-between">
                                <label
                                    for="when"
                                    class="block text-sm font-medium text-gray-700"
                                    >When</label>
                                {#if invalid}
                                    <span class="text-xs mt-1 text-red-500">Try with a date in the future.</span>
                                {/if}
                            </div>
                            <div class="mt-1 relative rounded-md shadow-sm">
                                <div
                                    class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <!-- Heroicon name: solid/clock -->
                                    <svg
                                        class="h-5 w-5 text-gray-400"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true">
                                        <path
                                            fill-rule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                </div>
                                <input
                                    type="text"
                                    name="when"
                                    id="when"
                                    on:input="{inputHandler}"
                                    class="focus:ring-orange-500 focus:border-orange-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="{whenInitial}" />
                            </div>
                            <p class="mt-2 text-sm text-{invalid ? 'red-500' : 'gray-500'} truncate">
                                {whenText}
                            </p>
                            
                        </div>

                        <!-- Level of activity -->
                        <div class="col-span-6">
                            <label
                                for="level"
                                class="block text-sm font-medium text-gray-700"
                                >Level</label>
                            <div class="grid grid-cols-3">
                                {#each ['social', 'active', 'performance'] as level}
                                <div class="rounded-full mx-1 my-2 bg-white p-1 ring ring-{level == rendevuo.level ? 'orange-600' : 'gray-200'}">
                                    <span class="text-sm capitalize truncate float-right mr-1 w-12 sm:w-auto md:w-auto lg:w-auto xl:w-auto 2xl:w-auto">{level}</span>
                                    <label >
                                        <input class="absolute opacity-0 w-0 h-0" type="radio" name="level" value="{level}" bind:group="{rendevuo.level}"/>
                                        <Heroicons key="{level}" />
                                    </label>
                                </div>
                                {/each}
                            </div>
                        </div>

                        <!-- About as in extra text details  -->
                        <div class="col-span-6">
                            <div class="flex justify-between">
                                <label
                                    for="about"
                                    class="block text-sm font-medium text-gray-700">
                                    About
                                </label>
                                <span class="text-xs mt-1"> ({aboutCharsLeft} chars) </span>
                            </div>

                            <div class="mt-1">
                                <textarea
                                    id="about"
                                    maxlength="{aboutMaxLength}"
                                    name="about"
                                    rows="2"
                                    bind:value={rendevuo.about}
                                    on:input="{inputHandler}"
                                    class="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    placeholder="..."
                                ></textarea>
                            </div>
                            <p class="mt-2 text-sm text-gray-500">
                                Short description of your rendevuo.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-4 flex justify-end">
            <button type="button" class="btn btn-gray" on:click="{_cancel}">
                Cancel
            </button>
            <button
                disabled={invalid}
                type="submit"
                class="ml-3 inline-flex justify-center btn btn-orange disabled:opacity-50">
                Create
            </button>
        </div>
    </form>
</section>
