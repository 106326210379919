import { api } from '../Utils/server';
import { gql } from 'apollo-boost';


async function findMembro() {
    const membro = await api(FIND_MEMBRO, {}, (response) => {
        if (response.data.findMembro) return response.data.findMembro;
    });

    return membro;
};

async function setConsent(_id) {
    const consent = await api(SET_CONSENT, { _id }, (response) => {
        return response.data.setConsentAccept || false;
    });

    return consent;
};

async function readNotu(_id) {
    const consent = await api(READ_NOTU, { _id }, (response) => {
        return response.data.readNotu || false;
    });

    return consent;
};

async function createMembro(consent=false) {
    const membro = await api(CREATE_MEMBRO, { consent }, (response) => {
        if (response.data.createMembro) return response.data.createMembro;
    });

    return membro;
};


const FIND_MEMBRO = gql`
    query findMembro {
        findMembro {
            _id
            sub
            location {
                latitude
                longitude
                city
            }
            notoj {
                _id
                text
                rendevuo
            }
            consent
        }
    }
`;

const SET_CONSENT = gql`
    mutation setConsent { setConsentAccept }
`;

const READ_NOTU = gql`
    mutation readNotu($_id: String!) { readNotu(_id: $_id) }
`;

const CREATE_MEMBRO = gql`
    mutation createMembro($consent: Boolean!) {
        createMembro( membro: { consent: $consent } ) {
            _id
            sub
            location {
                latitude
                longitude
                city
            }
            notoj {
                _id
                text
                rendevuo
            }
            consent
        }
    }
`;


export { createMembro, findMembro, setConsent, readNotu };
