<script>
    import { fade } from 'svelte/transition';
    import excapeOrClickOutside from '../Actions/escapeOrClickOutside';
import Heroicons from '../UI/Heroicons.svelte';
    
    export let rendevuo;

    let menuVisible = false;

    const shareUrl = window.document.location.origin + '/#r/' + rendevuo._id;

    function shareRendevuo() {
        if (navigator.share)
            navigator
                .share({
                    title: rendevuo.title,
                    text: rendevuo.title,
                    url: shareUrl,
                })
                .then(() => {
                    console.log('Thanks for sharing!');
                })
                .catch(console.error);
        else {
            // Navigation fallback
            menuVisible = true;
        }
    }
    
    function copyToClipboard() {
        navigator.clipboard
        .writeText(shareUrl)
            .then(() => {
                menuVisible = false;
            })
            .catch(console.error);
    }
</script>

{#if !rendevuo.deleted}
<div use:excapeOrClickOutside={menuVisible} on:excapeOrClickOutside={() => menuVisible = false} >
    <div class="cursor-pointer" on:click|stopPropagation={shareRendevuo} >
        <Heroicons key="share" />
    </div>
    {#if menuVisible}
        <div class="relative">
            <div
                transition:fade={{ duration: 100 }}
                class="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu">
                <span
                    on:click={copyToClipboard}
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem">Copy link</span>
            </div>
        </div>
    {/if}
</div>
{/if}