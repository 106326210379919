import { writable } from 'svelte/store';

const rendevuos = writable([]);

const _mutate = (_id, items, mutate) => {
    const index = items.findIndex(i => i._id === _id);
    if (index < 0) return items;

    const item = items[index];
    if (mutate(item)) {
        items[index] = item;
    } else {
        items.splice(index, 1);
    }

    return items;
}


const store = {
    subscribe: rendevuos.subscribe,

    set: data => {
        rendevuos.set(data);
    },
    add: rendevuo => {
        rendevuos.update(items => {
            return [{...rendevuo}, ...items];
        });
    },
    update: (_id, rendevuo) => {
        rendevuos.update(items => {
            return _mutate(_id, items, item => {item = {...item, ...rendevuo}; return true;});
        });
    },
    join: (_id, membro) => {
        rendevuos.update(items => {
            return _mutate(_id, items, item => {item.membros.push(membro); return true;});
        });
    },
    leave: (_id, _mid) => {
        rendevuos.update(items => {
            return _mutate(_id, items, item => {item.membros = item.membros.filter(membro => membro._id !== _mid); return !item.deleted;});
        });
    },
    cancel: (_id, notoj) => {
        rendevuos.update(items => {
            return _mutate(_id, items, item => {item.deleted = true; item.notoj.push(notoj); return true;});
        });
    }
}

export default store;
