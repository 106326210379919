export function formatDate(date, options, locale) {
    if (!options){
        options = {
            weekday: 'short',
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
	        minute: '2-digit',
        };
    }
    if (!locale) locale = 'en-GB';

    return new Date(date).toLocaleTimeString(locale, options);
};

export function partOfTheDay(datetime) {
    const hour = datetime.getHours();

    switch (hour) {
        case 5:
        case 6:
        case 7:
            return 'early morning';
        case 8:
        case 9:
        case 10:
            return 'morning';
        case 11:
            return 'late morning';
        case 12:
        case 13:
        case 14:
            return 'early afternoon';
        case 15:
            return 'afternoon';
        case 16:
            return 'late afternoon';
        case 17:
        case 18:
            return 'early evening';
        case 19:
        case 20:
            return 'evening';
        default:
            return 'night';
    }
}

