<script>
    export let rendevuo;
    export let currentMembro;

    import { slide, fade } from 'svelte/transition';

    import { formatDate } from './../Utils/date.js';
    import ListSlot from '../UI/ListSlot.svelte';
    import Avatar from '../UI/Avatar.svelte';
    import Heroicons from '../UI/Heroicons.svelte';
    import Questionmark from '../UI/Questionmark.svelte';

    import Menu from './RendevuoItemMenu.svelte';
    import { notifications } from './../stores.js';
    import excapeOrClickOutside from './../Actions/escapeOrClickOutside';

    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    import { api } from '../Utils/server';
    import { join_rendevuo, leave_rendevuo } from './queries';

    async function joinRendevuo() {
        const joined = await api(
            join_rendevuo,
            { _id: rendevuo._id },
            (response) => {
                if (response.data.joinRendevuo)
                    return response.data.joinRendevuo;
            }
        );

        if (joined) {
            dispatch('join', {
                _id: rendevuo._id,
                membro: {
                    _id: $currentMembro._id,
                    name: $currentMembro.name,
                    picture: $currentMembro.picture,
                },
            });
        }
    }

    const leaveRendevuo = () => {
        if (rendevuo.membros.length > 1) {
            _leaveRendevuo();
            return;
        }
        confirmationVisible = true;
    };
    async function _leaveRendevuo() {
        const left = await api(
            leave_rendevuo,
            { _id: rendevuo._id },
            (response) => {
                if (response.data.leaveRendevuo)
                    return response.data.leaveRendevuo;
            }
        );

        if (left) {
            dispatch('leave', { _id: rendevuo._id, _mid: $currentMembro._id });
        }
    }

    function getChronoBadgeText() {
        if (rendevuo.deleted) return 'cancelled';
        if (!$currentMembro) return '';

        var hourDifference = (new Date(rendevuo.time) - new Date()) / 36e5;
        if (hourDifference < 0) return 'done';
        if (rendevuo.membros.length + 1 >= rendevuo.capacity) return 'full';

        return hourDifference <= 72 ? 'soon' : '';
    }

    let joinedRendevuo = false;
    let meAsHolder = false;
    let chronoBadgeText = '';
    let distanceBadgeText = '';

    $: {
        if ($currentMembro) {
            joinedRendevuo = rendevuo.membros.some(
                (membro) => $currentMembro._id === membro._id
            );
            meAsHolder = $currentMembro._id === rendevuo.holder._id;
        }
        chronoBadgeText = getChronoBadgeText();
        distanceBadgeText = !$currentMembro
            ? 'latest'
            : rendevuo.distance > 100.0
            ? 'far'
            : '';
    }

    // Notification
    let notificationVisible = false;
    let notificationOpened = false;

    const cardClicked = () => {
        if ($currentMembro) return;
        notifications.set({
            title: 'Sign in!',
            text: 'Hi membro, Sign in to join "' + rendevuo.title + '".',
            type: 'warning',
        });
    };
    $: confirmationVisible = false;

    const capitalizeFirstLetter = ([first, ...rest]) =>
        first ? first.toUpperCase() + rest.join('') : '';

    let levelTextVisible = false;
</script>

{#if rendevuo}
    <ListSlot
        src="{rendevuo.map}"
        coordinates="{rendevuo.coordinates}"
        category="{rendevuo.category}"
        distance="{rendevuo.distance.toFixed(1)}">
        <div slot="confirmation">
            <!-- Confirm leaving the rendevuo  -->
            {#if confirmationVisible}
                <div
                    class="absolute z-20 inset-0 sm:overflow-y-auto lg:-inset-y-10">
                    <div
                        class="flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div
                            in:fade="{{ duration: 200 }}"
                            out:fade="{{ duration: 100 }}"
                            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby="modal-headline">
                            <div class="sm:flex sm:items-start">
                                <img
                                    class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10"
                                    src="https://app.ekscito.com/logo.png"
                                    alt="Ekscito" />
                                <div
                                    class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <h3
                                        class="text-lg leading-6 font-medium text-gray-900 truncate"
                                        id="modal-headline">
                                        Last membro to leave '{rendevuo.title}'
                                    </h3>
                                    <div class="mt-2">
                                        <p class="text-sm text-gray-500">
                                            Are you sure you want to leave the
                                            rendevuo? You are free to leave and
                                            create a new one if this do not fit
                                            your needs.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                                <button
                                    on:click="{() => {
                                        _leaveRendevuo();
                                        confirmationVisible = false;
                                    }}"
                                    type="button"
                                    class="inline-flex w-full justify-center rounded-md border border-gray-300 px-4 py-2 bg-white md:text-base text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:text-sm">
                                    Leave
                                </button>
                                <button
                                    on:click="{() =>
                                        (confirmationVisible = false)}"
                                    type="button"
                                    class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 md:text-base text-sm font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                                    Stay
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            {/if}
        </div>

        <div
            slot="card"
            on:click="{cardClicked}"
            class="relative p-3 bg-white rounded-lg shadow-lg {joinedRendevuo
                ? 'border-2 border-orange-500'
                : ''} {meAsHolder
                ? 'border-2 border-orange-500 ring ring-orange-600 ring-offset-4 ring-offset-orange-100'
                : ''} ">
            <div class="flex items-baseline">
                {#if $currentMembro}
                    <div
                        class="text-gray-600 text-xs uppercase font-semibold tracking-wide truncate">
                        {formatDate(rendevuo.time)}
                    </div>
                {:else}
                    <div class="flex items-center">
                        {new Date(rendevuo.time).toLocaleDateString('en-GB', {
                            weekday: 'short',
                            month: 'short',
                            day: '2-digit',
                        })}, at
                        <div class="px-1 pb-1">
                            <Questionmark
                                tooltipText="Sign in for time details"
                                w="5"
                                h="5" />
                        </div>
                    </div>
                {/if}

                <span
                    class="inline-block bg-orange-100 text-orange-600 text-xs uppercase font-semibold tracking-wide rounded-full px-2 ml-1"
                    >{chronoBadgeText}</span>
                <span
                    class="inline-block bg-green-100 text-green-600 text-xs uppercase font-semibold tracking-wide rounded-full px-2 ml-1"
                    >{distanceBadgeText}</span>
                <div class="flex ml-auto space-x-2">
                    {#if rendevuo.notoj && rendevuo.notoj.length > 0}
                        <div
                            on:click="{() => {
                                notificationVisible = true;
                                notificationOpened = true;
                            }}"
                            class="relative">
                            <span class="absolute inset-x-3 flex h-4 w-4">
                                {#if !notificationOpened}
                                    <span
                                        class="animate-ping absolute -inset-y-1 -inset-x-1 inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"
                                    ></span>
                                {/if}
                                <span
                                    class="relative inline-flex rounded-full h-2 w-2 bg-yellow-500"
                                ></span>
                            </span>
                            <Heroicons key="bell" />
                        </div>
                    {/if}
                    <Menu rendevuo="{rendevuo}" />
                </div>
            </div>

            <!-- Notification at rendevuo level -->
            {#if notificationVisible}
                <div
                    in:slide|local
                    out:fade
                    class="absolute z-20 items-center w-full inset-0 top-10 bottom-5">
                    <div
                        class="flex shadow-lg rounded-md mx-2 p-3 h-full bg-yellow-50 items-center">
                        <div class="flex-shrink-0">
                            <!-- Heroicon name: solid/exclamation -->
                            <svg
                                class="h-6 w-6 text-yellow-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true">
                                <path
                                    fill-rule="evenodd"
                                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                    clip-rule="evenodd"></path>
                            </svg>
                        </div>
                        <div class="ml-3 flex-1 md:flex md:justify-between">
                            <p class="text-sm text-yellow-700">
                                {rendevuo.notoj && rendevuo.notoj.length > 0
                                    ? rendevuo.notoj[0].text
                                    : 'This rendevuo might have some missing information'}
                            </p>
                        </div>
                        <div class="ml-3 flex-shrink-0 flex">
                            <button
                                on:click="{() => (notificationVisible = false)}"
                                class="rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
                                <span class="sr-only">Close</span>
                                <!-- Heroicon name: solid/x -->
                                <svg
                                    class="h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path
                                        fill-rule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clip-rule="evenodd"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            {/if}

            <h2 class="flex space-x-0.5 mt-1 text-lg font-semibold truncate">
                <!-- chat-alt-2-->
                {#if rendevuo.level}
                    <span
                        use:excapeOrClickOutside="{levelTextVisible}"
                        on:excapeOrClickOutside="{() =>
                            (levelTextVisible = false)}"
                        on:click|stopPropagation="{() => {
                            levelTextVisible = true;
                            setTimeout(() => {
                                levelTextVisible = false;
                            }, 5000);
                        }}"
                        on:mouseover="{() => (levelTextVisible = true)}"
                        on:mouseleave="{() => (levelTextVisible = false)}">
                        <Heroicons
                            key="{rendevuo.level}"
                            strokeColor="text-gray-600" />
                        {#if levelTextVisible}
                            <div
                                transition:fade="{{ duration: 100 }}"
                                class="absolute inset-y-4 h-1 z-50">
                                <div
                                    class="px-2 pt-1 whitespace-nowrap py-0.5 text-sm bg-orange-500 rounded-lg text-white">
                                    {capitalizeFirstLetter(rendevuo.level)}
                                </div>
                                <svg
                                    class=" text-orange-500 h-2 left-0 ml-2 top-full"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 255 255"
                                    xml:space="preserve"
                                    ><polygon
                                        class="fill-current"
                                        points="0,0 127.5,127.5 255,0"></polygon
                                    ></svg>
                            </div>
                        {/if}
                    </span>
                {/if}
                <span class="truncate">
                    {rendevuo.title}
                </span>
            </h2>

            <div
                class="mt-1 flex space-x-1 items-baseline text-sm text-gray-600">
                {rendevuo.about}
            </div>

            <div class="mt-3 flex items-center">
                <div class="flex -space-x-2 items-center">
                    {#each rendevuo.membros as m}
                        <Avatar
                            showNotification="{rendevuo.holder.name === m.name}"
                            src="{m.picture}"
                            alt="{m.name}"
                            tooltipText="{(rendevuo.holder.name === m.name
                                ? 'Creator: '
                                : '') + m.name}" />
                    {/each}
                    {#if $currentMembro}
                        {#if joinedRendevuo}
                            <button
                                on:click="{leaveRendevuo}"
                                aria-label="Leave rendevuo"
                                class="z-10 p-2 w-10 h-10 bg-orange-600 rounded-full hover:bg-orange-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">
                                <Heroicons
                                    key="user-remove"
                                    strokeColor="text-white" />
                            </button>
                        {:else if !rendevuo.deleted}
                            <button
                                on:click="{joinRendevuo}"
                                aria-label="Join rendevuo"
                                class="z-10 p-2 w-10 h-10 bg-orange-600 rounded-full hover:bg-orange-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">
                                <Heroicons
                                    key="user-add"
                                    strokeColor="text-white" />
                            </button>
                        {/if}
                    {/if}
                </div>
                <span class="ml-2 inline-block text-orange-600 font-semibold">
                    {rendevuo.membros.length || 0}
                    membro{rendevuo.membros.length > 1 ? 's' : ''}</span>
            </div>
        </div>
    </ListSlot>
{/if}
