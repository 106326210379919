import { query, mutate } from 'svelte-apollo';
import { createClient }from './apollo';
import { writable } from 'svelte/store';


const transactions = writable([]);

const store = {
    subscribe: transactions.subscribe,

    set: data => {
        transactions.set(data);
    },
    add: entry => {
        transactions.update(items => {
            return [...items.splice(0, 9), entry];
        });
    },
    clear: () => {
        transactions.set([]);
    },
}
const backend_uri = ENV_BACKEND_URI || 'http://localhost:8000/graphql';
if (backend_uri.indexOf('localhost') >= 0)
    console.log('Backend URI used: ', backend_uri);

const client = createClient(backend_uri);


async function _mutate(gql, variables) {
    const p = mutate(client, {
        mutation: gql,
        variables: variables,
    });

    return p;
}

async function _read(gql, variables) {
    const p = query(client, {
        query: gql,
        variables: variables,
    });

    return p.result();
}

async function api(gql, variables, onResult) {
    const read = 'query' == gql.definitions[0].operation;
    const transaction = gql.definitions[0].name ? gql.definitions[0].name.value : 'anonymous';
    const p = read ? _read : _mutate;

    const data = await p(gql, variables).then(response => {
        const parsed = onResult(response)
        const entry = (parsed? "OK" : "FAIL") + ": " + transaction;

        // force a fetch from backend by clearing the client cache on mutate 
        if (!read) client.clearStore();

        store.add(entry);

        return parsed;
    }).catch(error => {
        store.add("EXCEPTION: " + error);

        console.error('Exception:', error);
    });

    return data;
}

export { api, store };
