<script>
    import { fade } from 'svelte/transition';
    import excapeOrClickOutside from './../Actions/escapeOrClickOutside';

    export let tooltipText = '';
    export let src = '';
    export let alt = tooltipText;
    export let showNotification = false;
    let tooltipVisible = false;

</script>

<div use:excapeOrClickOutside={tooltipVisible} on:excapeOrClickOutside="{() => tooltipVisible = false}" class="relative flex items-center">
    <span on:click|stopPropagation="{() => {
        tooltipVisible = true;
        setTimeout(() => {
            tooltipVisible = false;
        }, 5000);
    }}"
    on:mouseover="{() => (tooltipVisible = true)}"
    on:mouseleave="{() => (tooltipVisible = false)}"
    class="inline-block relative">
        <img class="h-10 w-10 rounded-full" 
        src="{src}"
        alt="{alt}"
        >
        {#if showNotification}
            <img
                class="absolute -top-1 right-1 block h-3 bg-orange-100 rounded-full ring-2 ring-white"
                src="./logo.png"
                alt="creator"
            />
        {/if}
    {#if tooltipVisible}
        <div transition:fade="{{ duration: 100 }}" class="absolute -inset-y-8 h-1">
            <div
                class="px-2 whitespace-nowrap py-0.5 text-sm bg-orange-500 rounded-lg text-white"
            >
                {tooltipText}
            </div>
            <svg
                class=" text-orange-500 h-2 left-0 ml-4 top-full"
                x="0px"
                y="0px"
                viewBox="0 0 255 255"
                xml:space="preserve"
                ><polygon class="fill-current" points="0,0 127.5,127.5 255,0"
                    ></polygon></svg
            >
        </div>
    {/if}
</div>
