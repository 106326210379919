<script>
	import { onMount, setContext } from 'svelte';
	import { mapboxgl, key } from './mapbox.js';

	// import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
    
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

	setContext(key, {
		getMap: () => map
	});

	export let zoom;
	export let initial = { lat:55, lon:12 }; 

	let container;
	let map;
	let marker;

    const _change = (location) => {
        dispatch('change', {lat: location.lat, lon:location.lon});
    }

	onMount(() => {
		const styleBase = "mapbox://styles/mapbox/";
		const styles = ["satellite-streets-v10", "light-v10"]
		var style = 0;

		const link = document.createElement('link');
		link.rel = 'stylesheet';
		link.href = 'https://unpkg.com/mapbox-gl/dist/mapbox-gl.css';

		link.onload = () => {
			map = new mapboxgl.Map({
				container: container,
				style: styleBase + styles[style],
				zoom: zoom,
                center: [initial.lon, initial.lat],
			});

			map.on("load", setMarker);
			map.on('moveend', (e) => {
				marker.setLngLat(map.getCenter().wrap());
				var p = map.getCenter();
			    _change({lat: p.lat, lon:p.lng});
			});

			map.addControl(
				new MapboxGeocoder({
					language: "da",
					minLength: 3,
					marker: false,
					accessToken: mapboxgl.accessToken,
					mapboxgl: mapboxgl
				})
			);
		};

		document.head.appendChild(link);

		return () => {
			map.remove();
			link.parentNode.removeChild(link);
		};
	});

	var setMarker = () => {
		marker = new mapboxgl.Marker({offset:[8, -8], draggable: true})
			.setLngLat(map.getCenter().wrap())
			.addTo(map);

		marker.on("dragend", () => {
			var p = marker.getLngLat();
			map.setCenter(p);
			_change({lat: p.lat, lon:p.lng});
		});
	};
</script>

<style>
	div {
		width: 100%;
		height: 100%;
	}
</style>

<div bind:this={container}>
	{#if map}
		<slot></slot>
	{/if}
</div>