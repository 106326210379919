<script>
    import { currentMembro, location, filterState, notifications } from './../stores';
    import { onDestroy } from 'svelte';
    
    import Modal from './../UI/Modal.svelte';
    import Notification from './../UI/Notification.svelte';

    import CategoryIcon from '../UI/CategoryIcon.svelte';
    import { categoryKeys } from '../UI/category-types';
    import Heroicons from '../UI/Heroicons.svelte';

    let input = '';
    $: input = valid ? 'My location (' + $location.map(e => {return e.toFixed(2)}) + ')' : '';
    let valid = false;
    $: valid = $location && $location.length > 0;

    const iOS = (() => {
        const ua = window.navigator.userAgent;
        const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        const webkit = !!ua.match(/WebKit/i);

        return iOS && webkit;
        // const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    })();
    let showLocationHelpModal = false;
    $: showLocationHelpModal = $location && !valid;

    let myLocationLinkEnabled = true;
    let myLocationText = input;
    const iv = /\d+(\.\d+),\s*\d+(\.\d+)/g
    const myLocationFocus = (e) => {
        myLocationLinkEnabled = false;
        myLocationText = e.target.value;
        e.target.value = '';
        e.target.placeholder = '###.###,###.###';
    };
    const myLocationBlur = (e) => {
        myLocationLinkEnabled = true;
        if (e.target.value.match(iv)) {
            const split = e.target.value.split(',');
            $location = [parseFloat(split[0].trim()), parseFloat(split[1].trim())];
        } else {
            if (e.target.value) notifications.set({title: 'Failed to update location', text: 'Invalid location specified: ' + e.target.value, type: 'warning'});
            e.target.value = myLocationText;
        };
        e.target.placeholder = 'Location';
    };

    const categories = categoryKeys().map(c => { return {'name': c, 'checked': false || ($filterState.categories && $filterState.categories.includes(c))} });
    $: filterState.setCategories(categories.filter(c => { return c.checked }).map(c => { return c.name }));

    const levels = ['social', 'active', 'performance'].map(l => { return {'name': l, 'checked': false || ($filterState.levels && $filterState.levels.includes(l))} });
    $: filterState.setLevels(levels.filter(l => { return l.checked }).map(l => { return l.name }));
</script>

{#if showLocationHelpModal}
<Modal title="Location Sharing Help">
    <div class="flex items-start mb-2">
        <div class="flex items-center h-10 mt-4">
            {#if iOS}
            <p>
                Apple has a strict and very sensible privacy policy which can hinder the proper function of 
                Ekscito on the web. Please follow the linked steps to enable us to get your location from 
                your device
                <a class="underline text-red-800 font-black"
                    rel="noreferrer"
                    target="_blank"
                    href="https://support.apple.com/en-us/HT207092">
                here
                </a>.
            </p>
            {:else}
            <p>
                We could not determine your location either because of privacy restrictions or you explicitly
                declined the location sharing request. In the case of the former please ask your favorite
                search engine for advice on how to relax the restrictions for Ekscito.
            </p>
            {/if}
        </div>
    </div>

    <div slot="footer">
        <button
        type="button"
        class="mt-3 sm:mt-0 btn btn-orange text-orange-300 bg-orange-100 focus:outline-white"
        on:click="{() => {showLocationHelpModal = false;}}">Close</button>
    </div>
</Modal>
{/if}

<section>
<Notification/>
<div class="p-3 bg-white rounded-lg shadow-lg pt-8 space-y-2">
    <div class="relative bg-gray-200 rounded-lg">
        {#if !$filterState.single}
        <div class="absolute -inset-y-6 h-0 right right-0"><label class="text-gray-500 font-medium lg:font-semibold" for="onlyMine">Only mine&nbsp;</label><input class="focus:ring-orange-500 h-4 w-4 text-orange-600 border-gray-300 rounded" id="onlyMine" disabled={!$currentMembro} type=checkbox bind:checked={$filterState.linked}></div>
            <a href="https://www.google.com/maps/search/?api=1&query={valid ? $location.map(v => {return v.toFixed(8)}) : '0,0'}" rel="noreferrer" target="_blank" onclick="return {myLocationLinkEnabled};">
        
            <label
            class="absolute -inset-y-6 left-0 text-gray-500 font-medium lg:font-semibold"
            for="inline-activity">Filter</label>
            <div class="absolute inset-y-0 left-0 flex items-center pl-2">
                <svg
                class="h-6 w-6 stroke-current text-gray-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
            </div>
            <input
            id="inline-activity"
            type="text"
            size="26"
            _disabled
            autocomplete="off"
            class="bg-gray-200 pl-10 pr-6-6 py-1.5 lg:py-3 focus:bg-gray-200 focus:outline-none border-none rounded-lg text-gray-600"
            placeholder="Location"
            on:focus="{myLocationFocus}"
            on:blur="{myLocationBlur}"
            value={input} /></a>
        
        <div class="absolute inset-y-0 right-0 flex items-center pr-3">
            <button
                class:animate-pulse={!$location}
                class="border border-gray-400 py-1 px-2 rounded-md cursor-pointer focus:outline-none"
                alt="Locate me"
                aria-label="Locate me"
                on:click={location.ask}>
                <svg
                    class="stroke-current text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 512 512">
                    {#if !valid}
                        <path
                            d="M448,64,64,240.14H264a8,8,0,0,1,8,8V448Z"
                            style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px" />
                    {:else}
                        <path
                            d="M272,464a16,16,0,0,1-16-16.42V264.13a8,8,0,0,0-8-8H64.41a16.31,16.31,0,0,1-15.49-10.65,16,16,0,0,1,8.41-19.87l384-176.15a16,16,0,0,1,21.22,21.19l-176,384A16,16,0,0,1,272,464Z" />
                    {/if}
                </svg>
            </button>
        </div>
        {:else}
        <div class="absolute -inset-y-6 h-0 right right-0">
            <button class="btn-orange text-orange-300 bg-orange-100 focus:outline-black px-2 rounded-md cursor-pointer" alt="back to filter" on:click="{() => { window.location.hash = ''; }}">Back to filter</button>
        </div>
        {/if}
    </div>
    {#if !$filterState.single}
    <div class="relative bg-gray-200 rounded-lg">
        <!-- <label
        class="absolute -inset-y-6 left-0 text-gray-500 font-medium lg:font-semibold"
        for="inline-category">Categories</label> -->
        <div id="inline-category" class="flex flex-wrap content-center">
            {#each categories as category}
            <div style="{!$currentMembro ? 'filter: blur(2px); opacity(50);' : ''}" class="flex-item rounded-full mx-2 my-2 bg-white p-1 ring {category.checked ? 'ring-orange-600' : 'ring-gray-200'} ring-offset-4 ring-offset-orange-100" on:click="{() => {category.checked = !category.checked && $currentMembro}}">
                <CategoryIcon svg="{category.name}" />
            </div>
            {/each}
        </div>
    </div>    
    <div class="relative bg-gray-200 rounded-lg">
        <div id="inline-levels" class="flex flex-wrap content-center">
            {#each levels as level}
            <div style="{!$currentMembro ? 'filter: blur(2px); opacity(50);' : ''}" class="flex-item rounded-full mx-2 my-2 bg-white p-1 ring {level.checked ? 'ring-orange-600' : 'ring-gray-200'} ring-offset-4 ring-offset-orange-100" on:click="{() => {level.checked = !level.checked && $currentMembro}}">
                <span class="text-xs capitalize truncate float-right mx-1 w-10 sm:w-auto md:w-auto lg:w-auto xl:w-auto 2xl:w-auto">{level.name}</span>
                <Heroicons key="{level.name}" />
            </div>
            {/each}
        </div>
    </div>
    {/if}
</div>
</section>
